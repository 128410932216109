<template>
    <el-card shadow="never">
        <div class="top-search">
            <el-form :model="searchInfo" label-width="100px">
                <el-row>
                    <el-col :span="6">
                        <el-form-item label="商户号：">
                            <el-input v-model="searchInfo.merchant_no" placeholder="请输入商户号" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="商户名称：">
                            <el-input v-model="searchInfo.merchant_name" placeholder="请输入商户名称" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="合作商：">
                            <el-select v-model="searchInfo.agent_id" filterable placeholder="请选择合作商" clearable>
                                <el-option v-for="item in childAgent" :key="item" :label="item.company" :value="item.id" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="业务员：">
                            <el-select v-model="searchInfo.salesman_id" filterable placeholder="业务员" clearable>
                                <el-option v-for="item in salesmans" :key="item" :label="item.name" :value="item.id" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="6">
                        <el-form-item label="结算通道：">
                            <el-select v-model="searchInfo.payment_channel_id" placeholder="请选择结算通道" clearable>
                                <el-option v-for="(item, index) in settlementOptions" :key="index" :label="item.name"
                                    :value="item.id" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="支付方式:">
                            <el-select v-model="searchInfo.payment_method_id" placeholder="请选择支付方式" clearable>
                                <el-option :value="1" label="支付宝" />
                                <el-option :value="2" label="微信" />
                                <el-option :value="3" label="云闪付" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="10">
                        <el-form-item label="起止时间：">
                            <!-- <el-date-picker v-model="searchInfo.datetime" value-format="YYYY-MM-DD HH:mm:ss"
                                type="datetimerange" range-separator="至" start-placeholder="起始时间" end-placeholder="结束时间"
                                :default-value="searchInfo.datetime" /> -->

                            <el-date-picker v-model="searchInfo.datetime" value-format="YYYY-MM-DD" type="daterange" align="right" unlink-panels
                                range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
                                :picker-options="pickerOptions">
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :span="3">
                        <div class="btn-box">
                            <el-button type="primary" @click="handleSearch()" :icon="Search">查询</el-button>
                        </div>
                    </el-col>
                </el-row>
            </el-form>
        </div>
    </el-card>
    <div class="panel">
        <el-card shadow="hover" class="card">
            <template #header>
                <div class="card-header">
                    <span>交易笔数</span>
                </div>
            </template>
            <div class="card-body">
                <p>{{ orderData.pay_number ? orderData.pay_number : 0 }}</p>
                <p>交易笔数</p>
            </div>
        </el-card>
        <el-card shadow="hover" class="card">
            <template #header>
                <div class="card-header">
                    <span>交易总流水</span>
                </div>
            </template>
            <div class="card-body">
                <p>{{ orderData.pay_amount ? orderData.pay_amount : 0 }}</p>
                <p>交易总流水</p>
            </div>
        </el-card>
        <el-card shadow="hover" class="card">
            <template #header>
                <div class="card-header">
                    <span>总手续费</span>
                </div>
            </template>
            <div class="card-body">
                <p>{{ orderData.fee_mount ? parseFloat(orderData.fee_mount).toFixed(2) : 0 }}</p>
                <p>总手续费</p>
            </div>
        </el-card>
        <el-card shadow="hover" class="card">
            <template #header>
                <div class="card-header">
                    <span>总结算金额</span>
                </div>
            </template>
            <div class="card-body">
                <p>{{ orderData.pay_amount-orderData.fee_mount ?parseFloat(orderData.pay_amount-orderData.fee_mount).toFixed(2) : 0 }}</p>
                <p>总结算金额</p>
            </div>
        </el-card>
    </div>
    <div class="content">
        <div class="export">
            <el-button type="success" link :icon="Document" @click="hanldeExportExcel">导出Excel</el-button>
        </div>
        <c-table ref="orderTable" :tableDataUrl="tableDataUrl" :border="true" :isLoadData="false">
            <el-table-column prop="merchant_no" label="商户号" />
            <el-table-column prop="first_agent_name" label="渠道商" />
            <el-table-column prop="second_agent_name" label="合作商" />
            <el-table-column prop="merchant_name" label="商户名" />
            <el-table-column label="统计日期">
                <template #default="scope">
                    {{ searchDateTime.slice(0, 10) }} 至 {{ searchDateTime.slice(11, 21) }}
                    {{ scope.row.created_at ? "" : "" }}
                </template>
            </el-table-column>
            <el-table-column prop="pay_amount" label="交易流水" />
            <el-table-column prop="pay_number" label="订单量" />
            <el-table-column prop="refund_mount" label="退款金额" />
            <el-table-column prop="fee_mount" label="手续费" />
            <el-table-column prop="settlement_amount" label="结算金额" />
        </c-table>
    </div>
</template>
<script setup>
import { ref, getCurrentInstance, onMounted } from "vue"
import { Search, Document } from '@element-plus/icons-vue'
import cTable from '@/components/CustomTable'
import { timeFormat, getTodayStartEnd } from '@/func/time'
const { proxy } = getCurrentInstance()

const orderTable = ref(null)

// const date = new Date()
// const y = getFullTime(date.getFullYear())
// const m = getFullTime(date.getMonth() + 1)
// const d = getFullTime(date.getDate())

let searchInfo = ref({
    datetime: getTodayStartEnd()
})

const searchDateTime = ref({})

const tableDataUrl = proxy.$api.order.statistics.merchantStatistics

const handleSearch = () => {
    localStorage.setItem('searchInfo', searchInfo.value.datetime);
    searchDateTime.value =  localStorage.getItem('searchInfo');
    // console.log(searchDateTime.value);
    checkDateTime()
    orderTable.value.search(searchInfo.value)
    queryOrderCollect(searchInfo.value)
}

const hanldeExportExcel = () => {
    checkDateTime()
    searchInfo.value.downloadFile = true
    const filename = '代理商商户交易统计-' + timeFormat('', 'string')
    // console.log(proxy.$api.order.statistics);
    proxy.$api.order.statistics.export(searchInfo.value, filename)
}

// 结算通道
const settlementOptions = ref()
const querySettlement = () => {
    proxy.$api.channel.paymentChannels().then(r => {
        if (r.status == 200) {
            settlementOptions.value = r.data
        }
    })
}

// 合作商 partner
const childAgent = ref()
const queryChildAgent = () => {
    proxy.$api.agent.list({ pageSize: 999 }).then(r => {
        if (r.status == 200) {
            childAgent.value = r.data
        }
    })
}

// 业务员
const salesmans = ref()
const querySalesman = () => {
    proxy.$api.salesman.list({ pageSize: 999 }).then(r => {
        if (r.status == 200) {
            salesmans.value = r.data
        }
    })
}

// 面板数据
const orderData = ref({})
const queryOrderCollect = (params = {}) => {
    proxy.$api.order.statistics.merchantStatisticsCollect(params).then(r => {
        console.log(r.data);
        if (r.status == 200) {
            orderData.value = r.data
        }
    })
}

const checkDateTime = () => {
    if (searchInfo.value.datetime && searchInfo.value.datetime.length >= 2) {
        searchInfo.value.time_start = searchInfo.value.datetime[0] + ' 00:00:00'
        searchInfo.value.time_end = searchInfo.value.datetime[1] + ' 23:59:59'
    }
}


onMounted(() => {
    // 交易数据汇总
    orderTable.value.setCustomLoad(true)
    // handleSearch()

    // 支付通道
    querySettlement()

    // 业务员
    querySalesman()

    // 下级代理商
    queryChildAgent()

    // console.log(y, m, d);

})

</script>

<style lang="less" scoped>
.top-search {
    width: 100%;

    .btn-box {
        width: 100%;
        display: flex;
        justify-content: flex-end;
    }
}

.content {
    margin-top: 1.24rem;

    .pagination {
        margin: 1.25rem 0;
        display: flex;
        justify-content: flex-end;
    }

    .export {
        display: flex;
        justify-content: flex-end;
        padding: 10px 0;
    }
}

.moreinfo {
    width: 100%;
    border: 1px solid #f0f0f0;
    margin: 0 auto;

    .moreinfo-title,
    .moreinfo-content {
        width: 100%;
        padding: 10px;
        box-sizing: border-box;
        font-size: 12px;
        height: 40px;
    }

    .moreinfo-title {
        background-color: #fdfbfb;
    }

    .moreinfo-content {
        background: #fff;
    }
}

.panel {
    padding: 8px 0;
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;

    .card {
        width: 24%;

        .card-header {
            font-size: 18px;
            font-weight: bold;
        }

        .card-body {
            font-size: 14px;

            p {
                margin: 6px;
            }

            p:first-child {
                font-size: 20px;
                font-weight: bold;
            }
        }
    }
}
</style>